<template>
  <v-card>
    <v-container>
      <v-row>
        <v-col :cols="isMobile ? 10 : 6" class="pb-0">
          <v-card-title class="text-button" :class="isMobile ? 'pl-0' : ''"
            >Buscar medicamentos</v-card-title
          >
        </v-col>
        <v-col :cols="isMobile ? 2 : 6" class="pt-0 pr-0">
          <v-card-actions class="pb-0">
            <v-spacer></v-spacer>
            <v-tooltip text="Cerrar" location="left">
              <template v-slot:activator="{ props }">
                <v-btn
                  color="primary"
                  tabindex="99"
                  v-bind="props"
                  @keydown.tab="
                          isLoading ? null : focusComponent('nombreMedTextField',1)
                        "
                  :icon="closeIcon"
                  @click="closeModal()"
                >
                </v-btn>
              </template>
            </v-tooltip>
          </v-card-actions>
        </v-col>
      </v-row>

      <v-card-text class="py-0" :class="isMobile ? 'px-0' : ''">
        <v-form
          v-model="isSearchFormValid"
          form="search-form"
          id="search-form"
          ref="search-form"
          @submit.prevent="searchMedicamentos()"
        >
          <v-row :no-gutters="isMobile">
            <v-col
              :cols="isMobile ? 12 : 4"
              class="py-0"
              v-if="!medicamentosSearched"
            >
              <v-text-field
                label="Nombre"
                variant="outlined"
                tabindex="1"
                id="nombreMedTextField"
                density="compact"
                v-model.trim="nombre"
                :rules="[rules.minLength(nombre, 3)]"
              ></v-text-field>
            </v-col>
            <v-col
              :cols="isMobile ? 12 : 3"
              class="py-0"
              v-if="!medicamentosSearched"
            >
              <v-text-field
                label="Droga"
                tabindex="2"
                variant="outlined"
                density="compact"
                v-model.trim="droga"
                :rules="[rules.minLength(droga, 3)]"
              ></v-text-field>
            </v-col>
            <v-col
              :cols="isMobile ? 12 : 3"
              class="py-0"
              v-if="!medicamentosSearched"
            >
              <v-text-field
                label="N° identificador"
                variant="outlined"
                tabindex="3"
                density="compact"
                v-model.trim="nroIdentificador"
                :rules="[rules.maxLength(nroIdentificador, 10), rules.numeric]"
              ></v-text-field>
            </v-col>

            <v-col
              :cols="isMobile ? 6 : 6"
              class="pt-0"
              v-if="filtersApplied && filtersApplied.length > 0"
            >
              <v-chip
                v-for="(filter, key) in filtersApplied"
                :key="key"
                class="mr-2"
                label
                outlined
                color="primary"
              >
                {{
                  `${filter.label}: ${
                    Array.isArray(filter.model)
                      ? truncateText(getTextWhenFilterIsArray(filter))
                      : truncateText(filter.model)
                  }`
                }}
              </v-chip>
            </v-col>
            <v-col
              :cols="isMobile ? 12 : medicamentosSearched ? 6 : 2"
              class="py-0"
            >
              <v-btn
                v-if="!medicamentosSearched"
                color="primary"
                type="submit"
                tabindex="4"
                form="search-form"
                :loading="isLoading"
                :disabled="
                  !isSearchFormValid ||
                  ((droga == null || droga == '') &&
                    (nombre == null || nombre == '') &&
                    (nroIdentificador == null || nroIdentificador == ''))
                "
                >Buscar</v-btn
              >
              <v-btn
                v-else
                :size="isMobile ? 'small' : 'default'"
                :class="isMobile ? 'mt-2' : 'to-right'"
                :loading="isLoading"
                tabindex="4"
                color="primary"
                id="restablecerFiltrosbtn"
                @click="resetFilters()"
                >Restablecer filtros</v-btn
              >
            </v-col>
            <v-col v-show="items.length > 0" class="py-0">
              <p
                style="color: red"
                :style="isMobile ? 'font-size: 10px;' : ''"
                :class="isMobile ? 'pt-2' : ''"
              >
                (*) {{ isMobile ? "" : "Doble " }} Click sobre el medicamento
                para seleccionar.
              </p>
            </v-col>
            <v-col cols="12" class="py-0"
              >
              <v-card class="mt-2 mb-2">
              <v-data-table
                :headers="headers"
                :mobile="isMobile"
                :items="items"
                item-value="id"
                :no-data-text="medicamentosSearched ? 'No hay datos para esa búsqueda' : 'Ingrese filtro para realizar una búsqueda'"
                :hide-default-header="isMobile"
                hide-default-footer
                density="compact"
                :class="isMobile ? 'custom-card' : ''"
                :loading="isLoading"
                @dblclick:row="selectItem"
                @touchstart:row="selectItem"
                :items-per-page="isMobile ? 1 : 10"
                @update:page="searchMedicamentos()"
                hover
              >
              </v-data-table>
              </v-card>
              <v-row>
                    <v-col cols="12">
                      <v-btn
                        size="small"
                        class="to-right"
                        tabindex="5"
                        color="primary"
                        id="SiguienteBtn"
                        :disabled="isLoading || items.length === 0"
                        @keydown.left="
                          isLoading ? null : focusComponent('AnteriorBtn')
                        "
                        @keydown.tab="
                          isLoading ? null : focusComponent('restablecerFiltrosbtn',1)
                        "
                        @click="updatePageNumber('next')"
                      >
                        {{ isMobile ? "Siguiente" : "Siguiente página" }}
                      </v-btn>
                      <v-btn
                        size="small"
                        class="to-right mx-1"
                        tabindex="6"
                        color="primary"
                        id="AnteriorBtn"
                        :disabled="
                          isLoading || !medicamentosSearched || pageNumber == 1
                        "
                        @keydown.right="
                          isLoading ? null : focusComponent('SiguienteBtn')
                        "
                        @keydown.tab="
                          isLoading ? null : focusComponent('restablecerFiltrosbtn',0)
                        "
                        @click="updatePageNumber('previous')"
                        >{{ isMobile ? "Anterior" : "Anterior página" }}
                      </v-btn>
                    </v-col>
                  </v-row>
              </v-col
            >
          </v-row>
        </v-form>
      </v-card-text>
    </v-container>
  </v-card>
</template>

<script>
import enums from "@/utils/enums/index";
import rules from "@/utils/helpers/rules";
import { focusComponent } from "@/utils/helpers/htmlHelpers";


import { mapActions, mapGetters } from "vuex";

export default {
  name: "BusquedaAvanzadaMedicamentos",
  props: {
    financiadorId: {
      type: Number,
      required: true,
    },
    osId: {
      type: Number,
      required: false,
      default: null,
    },
    benId: {
      type: Number,
      required: false,
      default: null,
    },
    fecha: {
      type: Date,
      required: false,
      default: null,
    },
    planId: {
      type: Number,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      rules: rules,
      closeIcon: enums.icons.CLOSE_ICON,
      nroIdentificador: null,
      nombre: null,
      droga: null,
      headers: [
        { title: "N° identificador", sortable: false, key: "id" },
        { title: "Nombre", sortable: false, key: "nombrePresentacion" },
        { title: "Droga", sortable: false, key: "drogasNombre" },
      ],
      items: [],
      isLoading: false,
      pageSize: 10,
      pageNumber: 1,
      medicamentosSearched: false,
      filtersApplied: [],
      isSearchFormValid: false,
    };
  },
  computed: {
    ...mapGetters({
      isMobile: "menu/isMobile",
    }),
  },
  mounted() {
    if (
      this.osId != null &&
      this.benId != null &&
      this.planId != null &&
      this.fecha != null
    )
      this.headers.push({
        title: "Cobertura",
        sortable: false,
        key: "cobertura",
        align: "end",
      });
  },
  methods: {
    ...mapActions({
      fetchMedicamentosByParams: "taxonomy/fetchMedicamentosByParams",
      setAlert: "user/setAlert",
    }),
    focusComponent(refName, timeout) {
      focusComponent(refName, timeout);
    },
    customizeFiltersApplied() {
      this.filtersApplied = [];
      if (this.nroIdentificador) {
        this.filtersApplied.push({
          key: "nroIdentificador",
          label: "N° identificador",
          model: this.nroIdentificador,
        });
      }
      if (this.nombre) {
        this.filtersApplied.push({
          key: "nombre",
          label: "Nombre",
          model: this.nombre,
        });
      }
      if (this.droga) {
        this.filtersApplied.push({
          key: "droga",
          label: "Droga",
          model: this.droga,
        });
      }
    },
    truncateText(text, maxLength = 20) {
      return (text ?? "")?.length > maxLength
        ? (text ?? "").substring(0, maxLength) + "..."
        : text;
    },
    getTextWhenFilterIsArray(currentFilter) {
      if (currentFilter.model.length > 0 && currentFilter.model.length <= 3) {
        return currentFilter.model.map((m) => {
          return m.value ? m.value : m;
        });
      }
      if (currentFilter.model.length > 3) {
        let totalFiltros = currentFilter.model.length - 3;
        let firstThree = currentFilter.model.slice(0, 3).map((f) => {
          return f.value ? f.value : f;
        });
        return `${firstThree} y ${totalFiltros} más`;
      }
    },
    resetFilters() {
      this.isSearchFormValid = false;
      this.nroIdentificador = this.nombre = this.droga = null;
      this.medicamentosSearched = false;
      this.filtersApplied = this.items = [];
      this.pageNumber = 1;
    },
    async searchMedicamentos() {
      this.isLoading = true;
      this.medicamentosSearched = true;
      this.customizeFiltersApplied();
      try {
        this.pageNumber;
        const data = {
          nroIdentificador:
            this.nroIdentificador == null
              ? null
              : this.nroIdentificador == ""
              ? null
              : this.nroIdentificador,
          nombre: this.nombre ?? null,
          droga: this.droga ?? null,
          financiadorId: this.financiadorId,
          pageNumber: this.pageNumber,
          pageSize: this.pageSize,
          osId: this.osId,
          planId: this.planId,
          fecha: this.fecha,
          benId: this.benId,
        };
        const response = await this.fetchMedicamentosByParams(data);
        this.items = response;
      } catch (error) {
        console.error(error);
      }
      this.isLoading = false;
    },
    closeModal() {
      this.$emit("closeModalMedicamentos");
    },
    selectItem(_, data) {
      const medicamento = data.item;
      this.$emit("selectMedicamento", medicamento);
    },
    updatePageNumber(event) {
      switch (event) {
        case "next":
          this.pageNumber++;
          break;
        case "previous":
          this.pageNumber--;
          break;
        default:
          break;
      }
      this.searchMedicamentos();
    },
  },
};
</script>

<style scoped>
.custom-card {
  background-color: #75248b; /* Color personalizado */
  color: white; /* Color del texto */
}
</style>
