<template>
  <div>
    <v-card elevation="8" class="the-card">
      <span class="px-3 mb-0 title">{{ title }}</span>
      <v-color-picker
        v-model="colorSelected"
        class="mx-0 my-0"
        :swatches="swatches"
        show-swatches
        hide-inputs
      ></v-color-picker>
      <v-row justify="end" class="px-6 py-5">
        <v-tooltip location="right">
          <template #activator="{ props }">
            <v-icon
              size="22"
              v-bind="props"
              @click="$emit('onClear')"
              class="pl-3"
              >{{ clearIcon }}</v-icon
            >
          </template>
          <span>Quitar color</span>
        </v-tooltip>
        <v-spacer></v-spacer>
        <v-btn
          density="compact"
          variant="text"
          color="primary"
          @click="onClose"
          class="the-btn"
          >Seleccionar</v-btn
        >
      </v-row>
    </v-card>
  </div>
</template>

<script>
import enums from "@/utils/enums/index.js";

export default {
  name: "ColorPicker",
  data: () => ({
    colorSelected: "#02828f",
    clearIcon: enums.icons.CROSS_ICON,
    swatches: [
      ["#FF0000", "#AA0000", "#550000"],
      ["#FFFF00", "#AAAA00", "#555500"],
      ["#00FF00", "#00AA00", "#005500"],
      ["#00FFFF", "#00AAAA", "#005555"],
      ["#0000FF", "#0000AA", "#212121"],
    ],
  }),
  props: {
    title: {
      type: String,
      required: false,
    },
  },
  methods: {
    onClose() {
      console.log(this.colorSelected);
      this.$emit("onSelect", this.colorSelected);
    },
  },
};
</script>

<style lang="scss" scoped>
.the-card {
  border: 1px solid #dbdbdba8;
  border-radius: 3px;
  max-width: 300px;
  overflow: hidden;
}
:deep(.v-color-picker__controls) {
  padding-bottom: 0px !important;
}
:deep(.v-color-picker__swatches div) {
  padding-bottom: 0px !important;
}
:deep(.v-color-picker__swatch) {
  margin-bottom: 0px;
}
.the-btn {
  font-size: 12px;
}
.title {
  display: flex;
  justify-content: center;
  font-size: 14px !important;
  font-weight: 500 !important;
  max-height: 28px;
}
</style>
